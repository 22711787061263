import React from 'react'
import { Container,Row,Col } from 'reactstrap'
import instagramIcon from '../../assets/icons/instagram.png'
import xIcon from '../../assets/icons/x.png';
import youtubeIcon from '../../assets/icons/Youtube.png';
import githubIcon from '../../assets/icons/github.png';
import linkendinIcon from '../../assets/icons/linkendin.png'; 
import { Link } from 'react-router-dom';

const socialMedia  = [
    {
        name: 'nCount Instagram',
        icon : instagramIcon,
        url:'https://www.instagram.com/ncount_iofe/'
    },
    {
        name: 'nCount Youtube',
        icon : youtubeIcon,
        url:'https://www.youtube.com/@nCount-IOFE'
    },
    {
        name: 'nCount Github',
        icon : githubIcon,
        url:'https://github.com/Forest-Economy-Alliance'
    },
    {
        name: 'nCount X',
        icon : xIcon,
        url:'https://x.com/ncountforum'
    },
    {
        name: 'nCount Linkendin',
        icon : linkendinIcon,
        url:'https://www.linkedin.com/in/ncount-platform-for-sustainability-action-856679303'
    }
]


function Footer() {
  return (
    <div className='footer-container'>
            <Row>
                <Col md="3"  className='footer-links-left'>
                <Link style={{textDecoration:'none',color:'white'}}
                  to={{
                    pathname: '/privacy-policy',
                  }}>  <p>Privacy Policies</p></Link>
                  <p>Terms and Conditions</p>
                </Col>
                <Col md="6" xs="12" className='footer-icons-container'>
                    {
                        socialMedia.map((media)=>{
                            return(
                             <a className='footer-icon' href={media.url} target='_blank'><img className='footer-icon-img' src={media.icon} alt={media.name}/></a>
                            )
                        })
                    }
                </Col>
                <Col md="3" className='footer-links-right'>
                <Link style={{textDecoration:'none',color:'white'}} to="/contact-us">
                <p>Contact Us</p>
                </Link>
                <a style={{textDecoration:'none',color:'white'}} href='https://forum.ncount.in/' target='_'>
                  <p>Support</p>
                </a>
                </Col>
            </Row>
    </div>
  )
}

export default Footer