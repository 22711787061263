import React, { useState } from 'react'
import allForms from '../../../utils/AllForms.json';
import { Col, Row,Tooltip} from 'reactstrap';
import infoIcon from '../../../assets/icons/info.png'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

function MobDataCollectionForms() {
    const BASE_URL = "https://htj5xy3nrv.us-east-1.awsapprunner.com";
    console.log(allForms)
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userInfo);
    const appInfo = useSelector(state=>state.appInfo);
    const [loading, setLoading] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
 
  
    //   Request Access
const handleRequestAccess = async (Form) => {
    setSelectedForm(Form)
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/nCount/odk/user-form-access`, {
        projectId: Form.projectId,
        formId: Form.formId,
        roleId: Form.roleId,
        userId: user.odkUserId,
        location:user.location
      })
      .then(function (response) {
        setLoading(false);
        alert("You have the form access now.");
        // dispatch({ type: "UPDATE_ODK_FORM_ACCESS", payload: response.data });
        setSelectedForm(null);
      })
      .catch(function (error) {
        setLoading(false);
        alert("You already have this form access.");
        setSelectedForm(null);
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <div>
          <div style={{position:'relative',height:'65vh'}}>
        <Row style={{justifyContent:'center'}}>
    {
        allForms.map((form)=>{
     
            return (
                <Col xs="10" >
               <div className='gs-formname-div' onClick={()=>handleRequestAccess(form)}>
                <p className='gs-formname-text1'>{!(loading && form.formId==selectedForm?.formId)?form.name:'Loading...'}</p>
                <p className='gs-formname-text2'>{form.name2}</p>
               </div>
            </Col>
            )

            })
            }
        </Row>
        {/* <div className='gs-info-icon-div'>
            <img className='gs-info-icon' src={infoIcon} onClick={()=>setUserWantInfoAgain()}/>
            </div> */}
    </div>
    </div>
  )
}

export default MobDataCollectionForms