import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import editIcon from '../../../assets/icons/edit.png'
import { Col, Row, UncontrolledCollapse } from 'reactstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import GettingStarted from '../gs/Main';
import axios from 'axios';

function Profile() {
    const user = useSelector(state=>state.userInfo);
    const toggle = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [mobileCompany,setMobileCompany] = useState(null);
    const [mobileModel,setMobileModel] = useState(null);
    const [mobileInfoUpdatingLoadingTrue,setmobileInfoUpdatingLoadingTrue] = useState(false);
    const dispatch = useDispatch();
    const navigate= useNavigate()
    const updateMobileInfo = async() =>{
      setmobileInfoUpdatingLoadingTrue(true);
      await axios.post('https://9abrwrkc5a.execute-api.us-east-1.amazonaws.com/mobile-info',
        {
          mobile_company:mobileCompany,
          mobile_model:mobileModel,
          phoneNumber:user?.phoneNumber
        }
      )
      .then((resp)=>{
        // console.log("mobile_model data",resp?.data)
        setmobileInfoUpdatingLoadingTrue(false)
        toggle()
        dispatch({ type: "UPDATE_MOBILE_INFO",payload: mobileCompany});
      })
      .catch((err)=>{
        console.log('something went wrong',err);
        setmobileInfoUpdatingLoadingTrue(false)
      })
    }

    useEffect(()=>{
      if(!user?.mobile_company){
        toggle();
      }
    },[])
    useEffect(()=>{
        if(user?.userId==null){
         navigate('/mob-login')
        }
     },[user])
     const handleLogout =() =>{
        dispatch({ type: "LOG_OUT"});
      }
     const [pageActive,setPageActive] = useState(-1)
  return (
    <div>
        <Row style={{justifyContent:'center'}}>
        <div className='mob-profile-image-circle'>
        <img className='mob-profile-image' src={user?.userImageUri} />
        </div>
        <center>
        <p className='mob-profile-name-info'>{user?.name}</p>
        <p className='mob-profile-general-info'>{user?.organization},{user?.organizationName}</p>
        <Link to="/mob/edit-profile">
        <button className='mob-profile-edit-button' onClick={()=>console.log(true)}>Edit <span><img className='profile-edit-icon' src={editIcon}/></span></button>
        </Link>
        <div style={{marginTop:20}}>
      <p id="toggler1" className={pageActive==0 ?"landing-page-button-active landing-page-buttons" :"landing-page-buttons"} style={{width:180,padding:'12px 10px'}}  onClick={()=>setPageActive(0)}>Getting Started</p>
      <UncontrolledCollapse toggler="#toggler1">
        <GettingStarted/>
      </UncontrolledCollapse>
      <p className={pageActive==1 ?"landing-page-button-active landing-page-buttons" :"landing-page-buttons"} style={{width:180,padding:'12px 10px'}}  onClick={()=>setPageActive(1)}>My nCount</p>
      <p className={pageActive==2 ?"landing-page-button-active landing-page-buttons" :"landing-page-buttons"} style={{width:180,padding:'12px 10px'}}  onClick={()=>setPageActive(2)} >Advanced process Integrations</p>
        </div>
        <p className='mob-profile-logout-button' onClick={()=>handleLogout()}>Log out</p>
        </center>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <center>
<h6 className="mt-3 mb-4">Mobile Camera Specification Info</h6>
        <input
            className="login-pin-input profile-input mt-5 mb-4"
            style={{width:'100%',borderRadius:10}}
            value={mobileCompany}
            onChange={(e)=>setMobileCompany(e.target.value)}
            placeholder="Mobile Company Names"
            />
              <input
              value={mobileModel}
              onChange={(e)=>setMobileModel(e.target.value)}
            className="login-pin-input profile-input mt-5 mb-2"
            style={{width:'100%',borderRadius:10}}
            placeholder="Mobile Company Model"
            />
              <button
            className="profile-update-button mt-3 mb-1"
            style={{paddingTop:5,paddingBottom:5,paddingLeft:15,paddingRight:15,color:'white'}}
            onClick={updateMobileInfo}
          >
            {mobileInfoUpdatingLoadingTrue ? 'Loading':'Submit'}
          </button>
            </center>
        </ModalBody>
        <hr/>
        <center>
          <p style={{fontSize:13,fontWeight:600,marginBottom:5,marginTop:-5}}>Disclaimer</p>
        </center>
        <p style={{fontSize:12,textAlign:'left',padding:'5px 10px'}}>By sharing your phone's model and brand information, we ensure that no personal data is collected from your device. This information is strictly used for improving our computational models.
         We do not access or store any sensitive data from your device. Your privacy is our priority, and we are committed to safeguarding your personal information. 
         </p>
      </Modal>
    </div>
  )
}

export default Profile