import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import dataVisual from "../../assets/science/datavisual.png";
import drone from "../../assets/science/drone.png";
import eDna from "../../assets/science/eDNA.jpg";
import threeDImage from "../../assets/science/3D.jpg";
import openData from "../../assets/science/opendata.jpg";
import ion from "../../assets/science/ion.png";

function ScienceComp2() {
  const allScienceComp = [
    {
      name: "Visual Engine",
      desc: "Dive into high-resolution maps, uncover hidden hotspots, and chart the distribution of diverse species. Contribute your observations to unlock comparative insights - all powered by your active participation.",
      url: dataVisual,
    },
    {
      name: "UAV Engine",
      desc: "We're developing an open-source, automated workflow that uses drone imagery to produce highly accurate, large-scale maps of shrub, herb, and forest distribution at exceptionally high resolution.",
      url: drone,
    },
    {
      name: "eDNA Analytical Engine",
      desc: "Simplified eDNA collection and analysis for everyone. Discover new species, monitor vulnerable populations, and contribute to groundbreaking conservation efforts - all without physically disturbing the environment",
      url: eDna,
    },
    {
      name: "Agumented Reality Engine",
      desc: "Uncover the hidden world beneath the forest canopy with AR. Use nCount platform and app to visualize data layers on your surroundings, revealing insect populations, fungal networks, and even hidden animal tracks. Become a citizen scientist and unlock the secrets of nature",
      url: threeDImage,
    },
    {
      name: "Open Data and Tools",
      desc: "Tinker with open-source electronics, digital tools and resources that reveal biodiversity secrets. We believe in open access and ethical sharing of data and resources. Join our dynamic community, share your findings, and contribute to a healthier planet.",
      url: openData,
    },
    {
      name: "I wish to be...IoG",
      desc: "In the not-too-distant future, nCount aspires to evolve into the Internet of Greens (loG), a network of interconnected components seamlessly interacting and generating actionable insights for the health and prosperity of our planet.",
      url: ion,
    },
  ];

  return (
    <div>
      <Row className="science-main-row">
        <Container>
          <Row>
            <Col md="4" style={{ marginTop: "auto" }}>
              <p className="science-comp-text">
                Dive Deep in our world of technology and science Engines
              </p>
              <p className="science-comp-desc">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit,
              </p>
            </Col>
            <Col md="8">
              <div>
                <Row>
                  {allScienceComp.map((comp) => {
                    return (
                      <Col md="4" className="science-comp-column">
                        <div className='science-card-not-hovered'>
                              <img src={comp.url} alt={comp.name} className='science-comp-image'/>
                              <Button className='science-comp-button-updated'>{comp.name}</Button>
                              </div>
                        <div className="science-card-hovered">
                          <img
                            src={comp.url}
                            alt={comp.name}
                            className="science-comp-image-hovered"
                          />
                          <div>
                            <p className="science-card-desc ml-2">{comp.desc}</p>
                            <Button className="science-comp-button-updated">
                              {comp.name}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
}

export default ScienceComp2;
