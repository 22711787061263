import React, { useState } from 'react'
import { Col, Input, Row } from 'reactstrap'
import poster_bg from '../../assets/misc/compaigns_date_bg.png'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

function Compaigns() {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
  return (
    <div style={{width:'100vw',overflowX:'hidden'}}>
        <Row className='compaigns-main-row'>
            <Col md="5">
              <Row className='compaigns-main-col-1-row'>
                <p className='compaigns-main-col-1-text-1'>14</p>
                <div className='compaigns-main-col-1-text-2-div'>
                <p className='compaigns-main-col-1-text-2'>15</p>
                <p  className='compaigns-main-col-1-total-compaigns'>Total Campaigns</p>
                </div>
              </Row>
            </Col>
            <Col md="7">
            <p className='compaigns-main-col-2-heading'>Active Campaigns</p>
            <p className='compaigns-main-col-2-desc'>
            Discover our impactful GAP Table workshops, where global thought leaders and changemakers unite to identify and address critical gaps in society.
             Explore highlights from our past events, featuring discussions on climate change, economic inequality, and more. 
            </p>
            <div>
                <Link to="/dashboard">
                <Row className='compaigns-main-col-2-row'>
                    <div className='compaigns-main-col-2-div1'>
                        <img src={poster_bg} className='compaigns-main-col-2-div1-bg'/>
                        <div className='compaigns-main-col-2-div1-bg-div'>
                            <div className='compaigns-main-col-2-div1-bg-div1'>
                            <p className='compaigns-main-col-2-div1-bg-text1'>15</p>
                            <p className='compaigns-main-col-2-div1-bg-text2'>July</p>
                            </div>
                            <div className='compaigns-main-col-2-div1-bg-div2'>
                                <p className='compaigns-main-col-2-div1-bg-div2-text1'>
                                Agricultural Inventory
                                </p>
                                <p className='compaigns-main-col-2-div1-bg-div2-text2'>
                                Create Region Maps
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='compaigns-main-col-2-div1' onClick={toggle}>
                        <img src={poster_bg} className='compaigns-main-col-2-div1-bg'/>
                        <div className='compaigns-main-col-2-div1-bg-div'>
                        <div className='compaigns-main-col-2-div1-bg-div1'>
                            <p className='compaigns-main-col-2-div1-bg-text1'>05</p>
                            <p className='compaigns-main-col-2-div1-bg-text2'>October</p>
                            </div>
                            <div className='compaigns-main-col-2-div1-bg-div2'>
                            <p className='compaigns-main-col-2-div1-bg-div2-text1'>
                            Campaign -2
                            </p>
                            <p className='compaigns-main-col-2-div1-bg-div2-text2'>
                            One line about the campaign
                            </p>
                            </div>
                            </div>
                            </div> */}
                </Row>
                            </Link>
                {/* <Row className='compaigns-main-col-2-row mt-5' onClick={toggle}>
                    <div className='compaigns-main-col-2-div1'>
                        <img src={poster_bg} className='compaigns-main-col-2-div1-bg'/>
                        <div className='compaigns-main-col-2-div1-bg-div'>
                            <div className='compaigns-main-col-2-div1-bg-div1'>
                            <p className='compaigns-main-col-2-div1-bg-text1'>21</p>
                            <p className='compaigns-main-col-2-div1-bg-text2'>November</p>
                            </div>
                            <div className='compaigns-main-col-2-div1-bg-div2'>
                                <p className='compaigns-main-col-2-div1-bg-div2-text1'>
                                Campaign -3
                                </p>
                                <p className='compaigns-main-col-2-div1-bg-div2-text2'>
                                One line about the campaign
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='compaigns-main-col-2-div1' onClick={toggle}>
                        <img src={poster_bg} className='compaigns-main-col-2-div1-bg'/>
                        <div className='compaigns-main-col-2-div1-bg-div'>
                            <div className='compaigns-main-col-2-div1-bg-div1'>
                            <p className='compaigns-main-col-2-div1-bg-text1'>05</p>
                            <p className='compaigns-main-col-2-div1-bg-text2'>December</p>
                            </div>
                            <div className='compaigns-main-col-2-div1-bg-div2'>
                                <p className='compaigns-main-col-2-div1-bg-div2-text1'>
                                Campaign -4
                                </p>
                                <p className='compaigns-main-col-2-div1-bg-div2-text2'>
                                One line about the campaign
                                </p>
                            </div>
                        </div>
                    </div>
                </Row> */}
            </div>
            </Col>
            <Modal style={{width:350,padding:10}} centered isOpen={modal} toggle={toggle}>
        <ModalBody >
          <div>
            <p className='compaign-modal-input-text'>
            You will get all the updates in your mail box. 
            </p>
            <Input className='compaign-modal-input' placeholder='email'/>
            <center>
                <button className='compaign-modal-submit-button'>Submit</button>
                </center>
          </div>
        </ModalBody>
      </Modal>
        </Row>
    </div>
  )
}

export default Compaigns